export default [
	{title:'Users',link:'users',minimalRole:1},
	{title:'Companies',link:'companies',minimalRole:1},
	{title:'Public filters',link:'publicFilters',minimalRole:1},
	{title:'Service level agreements',link:'pausals',minimalRole:3},
	{title:'Projects',link:'projects',minimalRole:2},
	{title:'Statuses',link:'statuses',minimalRole:3},
	{title:'Units',link:'units',minimalRole:2},
	{title:'Price lists',link:'pricelists',minimalRole:2},
	{title:'Supplier',link:'suppliers',minimalRole:2},
	{title:'Tags',link:'tags',minimalRole:2},
	{title:'Invoices',link:'supplierInvoices',minimalRole:2},
	{title:'Roles',link:'roles',minimalRole:2},
	{title:'Work types',link:'taskTypes',minimalRole:2},
	{title:'Trip types',link:'tripTypes',minimalRole:2},
	{title:'Attributes',link:'attributes',minimalRole:2},
	{title:'Imaps',link:'imaps',minimalRole:3},
	{title:'SMTPs',link:'smtps',minimalRole:3},
]
