export const SET_FILTER = 'SET_FILTER';
export const SET_PROJECT = 'SET_PROJECT';
export const SET_MILESTONE = 'SET_MILESTONE';
export const SET_SEARCH = 'SET_SEARCH';
export const SET_COMPANY = 'SET_COMPANY';

//LOGIN AND USER
export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_USER_ID = 'SET_USER_ID';
export const DELETE_USER_DATA = 'DELETE_USER_DATA';
export const SET_USER_NOTIFICATIONS = 'SET_USER_NOTIFICATIONS';
export const SET_USER_STATUSES = 'SET_USER_STATUSES';

export const SET_CALENDAR_LAYOUT = 'SET_CALENDAR_LAYOUT';

export const SET_LAYOUT = 'SET_LAYOUT';
export const SET_TASKS_ORDER_BY = 'SET_TASKS_ORDER_BY';
export const SET_TASKS_ASCENDING = 'SET_TASKS_ASCENDING';
export const SET_TASKLIST_LAYOUT = 'SET_TASKLIST_LAYOUT';

export const SET_EXPENDITURES_ORDER_BY = 'SET_EXPENDITURES_ORDER_BY';
export const SET_EXPENDITURES_ASCENDING = 'SET_EXPENDITURES_ASCENDING';

export const SET_PASSWORDS_ORDER_BY = 'SET_PASSWORDS_ORDER_BY';
export const SET_PASSWORDS_ASCENDING = 'SET_PASSWORDS_ASCENDING';

export const SET_CMDB_ORDER_BY = 'SET_CMDB_ORDER_BY';
export const SET_CMDB_ASCENDING = 'SET_CMDB_ASCENDING';

export const SET_WIKI_ORDER_BY = 'SET_WIKI_ORDER_BY';
export const SET_WIKI_ASCENDING = 'SET_WIKI_ASCENDING';

//TASKS
export const SET_HELP_SIDEBAR_STATE_PROJECT = 'SET_HELP_SIDEBAR_STATE_PROJECT';
export const SET_HELP_SIDEBAR_STATE_MILESTONE = 'SET_HELP_SIDEBAR_STATE_MILESTONE';
export const SET_HELP_SIDEBAR_STATE_FILTER = 'SET_HELP_SIDEBAR_STATE_FILTER';

//REPORTS
export const SET_REPORT_YEAR = 'SET_REPORT_YEAR';
export const SET_REPORT_MONTH = 'SET_REPORT_MONTH';
export const SET_REPORT_FROM = 'SET_REPORT_FROM';
export const SET_REPORT_TO = 'SET_REPORT_TO';

export const ADD_SHOW_DATA_FILTER = 'ADD_SHOW_DATA_FILTER';
export const SET_SHOW_DATA_FILTER = 'SET_SHOW_DATA_FILTER';

//STORAGE

export const STORAGE_SET_COMPANIES = 'STORAGE_SET_COMPANIES';
export const STORAGE_COMPANIES_ACTIVE = 'STORAGE_COMPANIES_ACTIVE';

export const STORAGE_SET_ERROR_MESSAGES = 'STORAGE_SET_ERROR_MESSAGES';
export const STORAGE_ERROR_MESSAGES_ACTIVE = 'STORAGE_ERROR_MESSAGES_ACTIVE';

export const STORAGE_HELP_CALENDAR_EVENTS = 'STORAGE_HELP_CALENDAR_EVENTS';
export const STORAGE_HELP_CALENDAR_EVENTS_ACTIVE = 'STORAGE_HELP_CALENDAR_EVENTS_ACTIVE';

export const STORAGE_SET_HELP_FILTERS = 'STORAGE_SET_HELP_FILTERS';
export const STORAGE_HELP_FILTERS_ACTIVE = 'STORAGE_HELP_FILTERS_ACTIVE';

export const STORAGE_HELP_INVOICE_ITEMS = 'STORAGE_HELP_INVOICE_ITEMS';
export const STORAGE_HELP_INVOICE_ITEMS_ACTIVE = 'STORAGE_HELP_INVOICE_ITEMS_ACTIVE';

export const STORAGE_SET_HELP_MILESTONES = 'STORAGE_SET_HELP_MILESTONES';
export const STORAGE_HELP_MILESTONES_ACTIVE = 'STORAGE_HELP_MILESTONES_ACTIVE';

export const STORAGE_SET_HELP_PRICELISTS = 'STORAGE_SET_HELP_PRICELISTS';
export const STORAGE_HELP_PRICELISTS_ACTIVE = 'STORAGE_HELP_PRICELISTS_ACTIVE';

export const STORAGE_SET_HELP_PRICES = 'STORAGE_SET_HELP_PRICES';
export const STORAGE_HELP_PRICES_ACTIVE = 'STORAGE_HELP_PRICES_ACTIVE';

export const STORAGE_SET_HELP_PROJECTS = 'STORAGE_SET_HELP_PROJECTS';
export const STORAGE_HELP_PROJECTS_ACTIVE = 'STORAGE_HELP_PROJECTS_ACTIVE';

export const STORAGE_SET_HELP_STATUSES = 'STORAGE_SET_HELP_STATUSES';
export const STORAGE_HELP_STATUSES_ACTIVE = 'STORAGE_HELP_STATUSES_ACTIVE';

export const STORAGE_HELP_STORED_ITEMS = 'STORAGE_HELP_STORED_ITEMS';
export const STORAGE_HELP_STORED_ITEMS_ACTIVE = 'STORAGE_HELP_STORED_ITEMS_ACTIVE';

export const STORAGE_HELP_SUPPLIER_INVOICES = 'STORAGE_HELP_SUPPLIER_INVOICES';
export const STORAGE_HELP_SUPPLIER_INVOICES_ACTIVE = 'STORAGE_HELP_SUPPLIER_INVOICES_ACTIVE';

export const STORAGE_HELP_SUPPLIERS = 'STORAGE_HELP_SUPPLIERS';
export const STORAGE_HELP_SUPPLIERS_ACTIVE = 'STORAGE_HELP_SUPPLIERS_ACTIVE';

export const STORAGE_SET_HELP_TAGS = 'STORAGE_SET_HELP_TAGS';
export const STORAGE_HELP_TAGS_ACTIVE = 'STORAGE_HELP_TAGS_ACTIVE';

export const STORAGE_SET_HELP_TASK_CUSTOM_ITEMS = 'STORAGE_SET_HELP_TASK_CUSTOM_ITEMS';
export const STORAGE_HELP_TASK_CUSTOM_ITEMS_ACTIVE = 'STORAGE_HELP_TASK_CUSTOM_ITEMS_ACTIVE';

export const STORAGE_SET_HELP_TASK_MATERIALS = 'STORAGE_SET_HELP_TASK_MATERIALS';
export const STORAGE_SET_HELP_TASK_MATERIALS_ACTIVE = 'STORAGE_SET_HELP_TASK_MATERIALS_ACTIVE';

export const STORAGE_SET_HELP_TASK_TYPES = 'STORAGE_SET_HELP_TASK_TYPES';
export const STORAGE_HELP_TASK_TYPES_ACTIVE = 'STORAGE_HELP_TASK_TYPES_ACTIVE';

export const STORAGE_HELP_TASK_WORK_TRIPS = 'STORAGE_HELP_TASK_WORK_TRIPS';
export const STORAGE_HELP_TASK_WORK_TRIPS_ACTIVE = 'STORAGE_HELP_TASK_WORK_TRIPS_ACTIVE';

export const STORAGE_SET_HELP_TASK_WORKS = 'STORAGE_SET_HELP_TASK_WORKS';
export const STORAGE_SET_HELP_TASK_WORKS_ACTIVE = 'STORAGE_SET_HELP_TASK_WORKS_ACTIVE';

export const STORAGE_SET_HELP_TASKS = 'STORAGE_SET_HELP_TASKS';
export const STORAGE_HELP_TASKS_ACTIVE = 'STORAGE_HELP_TASKS_ACTIVE';

export const STORAGE_HELP_TRIP_TYPES = 'STORAGE_HELP_TRIP_TYPES';
export const STORAGE_HELP_TRIP_TYPES_ACTIVE = 'STORAGE_HELP_TRIP_TYPES_ACTIVE';

export const STORAGE_SET_HELP_UNITS = 'STORAGE_SET_HELP_UNITS';
export const STORAGE_HELP_UNITS_ACTIVE = 'STORAGE_HELP_UNITS_ACTIVE';

export const STORAGE_SET_HELP_WORK_TYPES = 'STORAGE_SET_HELP_WORK_TYPES';
export const STORAGE_HELP_WORK_TYPES_ACTIVE = 'STORAGE_HELP_WORK_TYPES_ACTIVE';

export const STORAGE_IMAPS = 'STORAGE_IMAPS';
export const STORAGE_IMAPS_ACTIVE = 'STORAGE_IMAPS_ACTIVE';

export const STORAGE_SET_METADATA = 'STORAGE_SET_METADATA';
export const STORAGE_METADATA_ACTIVE = 'STORAGE_METADATA_ACTIVE';

export const STORAGE_SET_SMTPS = 'STORAGE_SET_SMTPS';
export const STORAGE_SMTPS_ACTIVE = 'STORAGE_SMTPS_ACTIVE';

export const STORAGE_USERS = 'STORAGE_SET_USERS';
export const STORAGE_USERS_ACTIVE = 'STORAGE_USERS_ACTIVE';

export const STORAGE_SET_HELP_COMPANY_INVOICES = 'STORAGE_SET_HELP_COMPANY_INVOICES';
export const STORAGE_HELP_COMPANY_INVOICES_ACTIVE = 'STORAGE_HELP_COMPANY_INVOICES_ACTIVE';
